import React, { useEffect, useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import NumericFormat from 'react-number-format';
import LadiDropdown from '../../../components/LadiDropdown';
import appConfig from '../../../config/app';
import BaseHelper from '../../../helpers/BaseHelper';
import actionAffiliate from '../../../redux/futures/affiliate/actions';
import actionCustomer from '../../../redux/futures/customer/actions';
import actionCommission from '../../../redux/futures/commission/actions';
import actionCommissionPlan from '../../../redux/futures/commission_plan/actions';
import actionIntegration from '../../../redux/futures/integrate_app/actions';
import DefineConst from '../../affiliates/DefineConst';
import DefineConstCommission from '../DefineConst';

// eslint-disable-next-line max-lines-per-function
function ModalCommission(props) {
    const defaultConditions = {
        page: 1,
        status: DefineConst.AFFILIATE_STATUS.ACTIVED,
        limit: appConfig.PAGINATION.DEFAULT_LIMIT,
        sort: { created_at: -1 },
    };
    const defaultCustomerConditions = {
        page: 1,
        affiliate_id: '-1',
        limit: appConfig.PAGINATION.DEFAULT_LIMIT,
        sort: { created_at: -1 },
    };
    const moment = BaseHelper.getMoment();
    const defaultCommission = {
        type: 'ORDER',
        status: 'PENDING',
        total: '',
        amount: '',
        commission_date: moment(),
        commission_payment_type_level_1: 'FIXED',
        commission_payment_type_level_2: 'FIXED',
        commission_payment_type_level_all: 'FIXED',
    };
    const { t, isEdit, cbProp } = props;
    const dispatch = useDispatch();
    const [commission, setCommission] = useState(defaultCommission);
    const [affiliate, setAffiliate] = useState({});
    const [customer, setCustomer] = useState({});
    const [affiliateConditions, setAffiliateConditions] = useState(defaultConditions);
    const [customerConditions, setCustomerConditions] = useState(defaultCustomerConditions);
    const [dateExpired, setDateExpired] = useState(moment());
    const [focused, setFocused] = useState(false);
    const commissionData = useSelector((state) => (state.commissions.commission));
    const listAffiliate = useSelector((state) => ({ ...{ items: [], total: 0, summary: {} }, ...state.affiliates.affiliates }));
    const listCustomer = useSelector((state) => ({ ...{ items: [], total: 0, summary: {} }, ...state.customers.customers }));
    const listAppIntegrate = useSelector((state) => ({ ...{ items: [], total: 0 }, ...state.appIntegrate.integrateApps }));
    const levelAffiliate = useSelector((state) => (state.affiliates.levelAffiliate));
    const commissionPlanDefault = useSelector((state) => (state.commissionPlans.commissionPlanDefault));

    const cb = () => {
        setDateExpired(null);
        setAffiliate({});
        setCustomer({});
        setCommission(defaultCommission);
        window.LadiUI.closeModal('modal-commission-custom');
        cbProp();
    };

    const eventSelectAffiliate = (item) => {
        setCommission({ ...commission, affiliate_id: item._id, affiliate_code: item.code });
        setCustomerConditions({ ...customerConditions, affiliate_id: item._id });
        setAffiliate(item);
        setCustomer({});
        dispatch(actionAffiliate.getLevelAffiliate({ id: item._id }, false));
    };

    const eventSelectCustomer = (item) => {
        setCommission({
            ...commission,
            customer_id: item._id,
            customer_name: item.name,
            customer_email: item.email,
            customer_phone: item.phone,
            customer_address: item.address,
        });
        setCustomer(item);
    };

    const eventChooseCommissionDate = (date) => {
        setDateExpired(date);
        const item = { ...commission, commission_date: date };
        setCommission(item);
    };

    const eventSelectType = (type) => {
        const item = {
            ...commission,
            type,
            commission_calc_by: 'BY_COMMISSION',
            commission_payment_type_level_1: 'PERCENT',
            commission_payment_type_level_2: 'PERCENT',
            commission_payment_type_level_all: 'PERCENT',
        };
        setCommission(item);
    };

    const eventEnableCommissionPlan = (type) => {
        const item = {
            ...commission,
            type: DefineConstCommission.COMMISSION_TYPE.PER_PRODUCT,
            use_commission_plan: type,
        };
        setCommission(item);
        if (type === 'YES') {
            dispatch(actionCommissionPlan.getDefaultCommissionPlan());
        }
    };

    const eventSelectStatus = (status) => {
        const item = { ...commission, status };
        setCommission(item);
    };

    const eventSelectShopId = (shopid) => {
        const item = { ...commission, shop_id: shopid };
        setCommission(item);
    };

    const eventSelectSource = (source) => {
        const item = { ...commission, source, shop_id: null };
        setCommission(item);
        dispatch(actionIntegration.list({
            sort: { _id: -1 },
            limit: 1000,
            group: { $ne: 'INTEGRATION' },
            type: source,
        }));
    };

    const eventCancelModal = () => {
        setDateExpired(null);
        setAffiliate({});
        setCustomer({});
        setCommission(defaultCommission);
        window.LadiUI.closeModal('modal-commission-custom');
    };

    const eventSubmitCommission = () => {
        if (!commission.affiliate_id || !commission.commission_date) {
            window.LadiUI.toast('danger', '', 'Vui lòng nhập đầy đủ thông tin affiliate và ngày tạo hoa hồng', 5000, 'bottom-left');
            return;
        }
        if (!['KPIS'].includes(commission.type) && (!commission.customer_email || !commission.customer_phone || !commission.customer_name)) {
            window.LadiUI.toast('danger', '', 'Vui lòng nhập đầy đủ thông tin khách hàng', 5000, 'bottom-left');
            return;
        }
        if (!['LEAD', 'NEW_CUSTOMER', 'KPIS'].includes(commission.type) && (!commission.code || !commission.source || !commission.shop_id)
            && commission.use_commission_plan !== 'YES') {
            window.LadiUI.toast('danger', '', 'Vui lòng nhập đầy đủ thông tin đơn hàng', 5000, 'bottom-left');
            return;
        }
        if (commission.type === 'PER_PRODUCT' && (!commission.product_code || !commission.product_price || !commission.product_quantity)
            && commission.use_commission_plan !== 'YES') {
            window.LadiUI.toast('danger', '', 'Vui lòng nhập đầy đủ thông tin sản phẩm', 5000, 'bottom-left');
            return;
        }
        const dataSubmit = commission;
        if (isEdit) {
            dataSubmit.id = commission._id;
            dispatch(actionCommission.update(dataSubmit, cb));
        } else {
            dispatch(actionCommission.create(dataSubmit, cb));
        }
    };

    const getListIntegration = () => {
        let integrations = [];
        for (let i = 0; i < listAppIntegrate.items.length; i++) {
            integrations.push({ name: listAppIntegrate.items[i].name, value: listAppIntegrate.items[i].config.shop_id });
        }
        return integrations;
    };

    const eventAddMoreProduct = () => {
        const item = {
            id: BaseHelper.generateRandomString(16),
            sku: '',
            price: '',
            quantity: '',
        };
        const moreProductItem = commission.more_product_items || [];
        moreProductItem.push(item);
        setCommission({ ...commission, more_product_items: moreProductItem });
    };

    const eventRemoveProduct = (prod) => {
        const items = [...commission.more_product_items];
        const newItems = items.filter((el) => el.id !== prod.id);
        setCommission({ ...commission, more_product_items: newItems });
    };

    const changeItemProd = (prod, key, value) => {
        const items = [...commission.more_product_items];
        const newItems = [];
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            if (item.id === prod.id) {
                item[key] = value;
            }
            newItems.push(item);
        }
        setCommission({ ...commission, more_product_items: newItems });
    };

    useEffect(() => {
        const data = { ...commission, affiliate_level: levelAffiliate };
        if (levelAffiliate === 1) {
            data.enable_commission_parent = false;
        }
        if (levelAffiliate > 3) {
            data.commission_calc_target_by = 'PARENT_LEVEL';
        }
        setCommission(data);
    }, [levelAffiliate]);

    useEffect(() => {
        if (commissionData) {
            const data = {
                code: commissionData.data ? commissionData.data.code : '',
                method: commissionData.data ? commissionData.data.method : '',
                source: commissionData.data ? commissionData.data.source : '',
                ip_address: commissionData.data ? commissionData.data.ip_address : '',
                customer_id: commissionData.customer ? commissionData.customer._id : null,
                customer_name: commissionData.customer ? commissionData.customer.name : null,
                customer_phone: commissionData.customer ? commissionData.customer.phone : null,
                customer_email: commissionData.customer ? commissionData.customer.email : null,
                customer_address: commissionData.customer ? commissionData.customer.address : null,
                product_code: commissionData.product ? commissionData.product.sku : null,
                product_quantity: commissionData.product ? (commissionData.product.price === 0 ? 0 : commissionData.product.total / commissionData.product.price) : null,
                product_price: commissionData.product ? commissionData.product.price : null,
                ...commissionData,
            };
            setCommission(data);
            let affiliateData = {};
            for (let i = 0; i < listAffiliate.items.length; i++) {
                if (listAffiliate.items[i]._id === commissionData.affiliate_id) {
                    affiliateData = listAffiliate.items[i];
                }
            }
            setAffiliate(affiliateData);
            setDateExpired(moment(commissionData.commission_date));
            setCustomer(commissionData.customer || {});
        }
    }, [commissionData]);

    useEffect(() => {
        if (!isEdit) {
            setDateExpired(moment());
            setAffiliate({});
            setCustomer({});
            setCommission(defaultCommission);
        }
    }, [isEdit]);

    useEffect(() => {
        dispatch(actionAffiliate.list(affiliateConditions));
    }, [affiliateConditions]);

    useEffect(() => {
        dispatch(actionCustomer.list(customerConditions));
    }, [customerConditions]);

    return <>
        <div className="ladiui modal modal-custom-2" id="modal-commission-custom">
            <div className="ladiui modal-dialog modal-dialog-centered custom-popups-width ">
                <div className="ladiui modal-content custom-ldp">
                    <div className="ladiui modal-header custom-ldp">
                        <label className="ladiui title-text-form-lever1">{isEdit ? 'Chỉnh sửa hoa hồng' : 'Tạo hoa hồng'}</label>
                        <button type="button" data-dismiss="modal" data-target="modal-export-commission" className="ladiui modal-close">
                            <i className="ladiui icon icon-ldp-close"></i>
                        </button>
                    </div>
                    <div>
                        <div className="d-grid-2">
                            {!isEdit
                                ? <div className="ladiui form-group-custom" style={{ width: '579px' }}>
                                    <label className="ladiui title-text-form-lever2" >Affiliate</label>
                                    <div className="ladiui select-search dropdown">
                                        <button
                                            className={'ladiui btn btn-size-34 btn-outline-light dropdown-toggle w-100-percent'}
                                            data-toggle="dropdown" style={{ maxWidth: '100%' }}>
                                            <i></i>
                                            <span className="ladiui dropdown-text" style={{ marginRight: 0, maxWidth: '100%' }}>
                                                {affiliate.email ? `${affiliate.fullname} (${affiliate.code}) - ${affiliate.email}` : 'Chọn affiliate'}
                                            </span>
                                        </button>
                                        <ul className="ladiui dropdown-menu dropdown-right" style={{ width: '100%' }}>
                                            <li className="ladiui search-group custom-people">
                                                <input
                                                    className="ladiui search-field dropdown-toggle form-control custom-people"
                                                    onChange={(e) => setAffiliateConditions({ ...affiliateConditions, keyword: e.target.value })}
                                                    placeholder="Tìm kiếm"
                                                />
                                                <i className="ladiui icon icon-search"></i>
                                            </li>
                                            {listAffiliate.total > 0
                                                ? listAffiliate.items.map((item) => <li key={item._id}
                                                    onClick={() => eventSelectAffiliate(item)} >
                                                    <a className="ladiui dropdown-item" href="#/">
                                                        {item.fullname} ({item.code}) - {item.email}
                                                    </a>
                                                </li>)
                                                : <li>
                                                    <a className="ladiui dropdown-item" href="#/">Không tìm thấy kết quả</a>
                                                </li>
                                            }
                                            {listAffiliate.total > listAffiliate.items.length
                                                ? <li>
                                                    <label className="ladiui search-checkbox default-text" ><a>...</a></label>
                                                </li>
                                                : <></>
                                            }
                                        </ul>
                                    </div>
                                </div>
                                : <div className="ladiui form-group-custom" style={{ width: '579px' }}>
                                    <label className="ladiui title-text-form-lever2" >Affiliate</label>
                                    <input type="email" readOnly className="ladiui form-control" placeholder="Nhập giá trị"
                                        value={affiliate.email ? `${affiliate.fullname} (${affiliate.code}) - ${affiliate.email}` : 'Chọn affiliate'} />
                                </div>}
                            <div className="ladiui form-group-custom commission-date-input commission-date-input-size-34">
                                <label className="ladiui title-text-form-lever2" >Ngày tạo hoa hồng</label>
                                <SingleDatePicker
                                    date={dateExpired} // momentPropTypes.momentObj or null
                                    onDateChange={(date) => eventChooseCommissionDate(date)} // PropTypes.func.isRequired
                                    focused={focused} // PropTypes.bool
                                    onFocusChange={({ focused }) => setFocused(focused)} // PropTypes.func.isRequired
                                    numberOfMonths={1}
                                    showClearDate={true}
                                    noBorder={false}
                                    placeholder="Chọn ngày"
                                    readOnly={true}
                                    isOutsideRange={(day) => false}
                                    showDefaultInputIcon={true}
                                    inputIconPosition="after"
                                    small={true}
                                    customInputIcon={
                                        <img src="https://w.ladicdn.com/ladiui/ladishare/calendar0808.svg" />
                                    }
                                />
                            </div>
                        </div>
                        <div className="d-grid-4">
                            <div className="ladiui form-group-custom">
                                <label className="ladiui title-text-form-lever2" >Sử dụng cấu hình tự động</label>
                                <LadiDropdown
                                    listProp={[{ value: 'YES', name: 'Có' }, { value: 'NO', name: 'Không' }]}
                                    valueProp={commission.use_commission_plan || 'NO'}
                                    hideLabel={true}
                                    validProp={true}
                                    classDropdown="dropdown-custom"
                                    classSize="btn-size-34"
                                    defaultTextProp="Chọn loại"
                                    cbProp={eventEnableCommissionPlan}
                                />
                            </div>

                            {
                                commission.use_commission_plan === 'YES'
                                    ? <>
                                        <div className="ladiui form-group-custom">
                                            <label className="ladiui title-text-form-lever2" >Loại hoa hồng</label>
                                            <input readOnly defaultValue="Tự động tính toán" className="ladiui form-control" />
                                        </div>
                                        <div className="ladiui form-group-custom">
                                            <label className="ladiui title-text-form-lever2" >Hoa hồng</label>
                                            <input readOnly defaultValue="Tự động tính toán" className="ladiui form-control" />
                                        </div>
                                    </>
                                    : <>
                                        {!isEdit
                                            ? <div className="ladiui form-group-custom">
                                                <label className="ladiui title-text-form-lever2" >Loại hoa hồng</label>
                                                <LadiDropdown
                                                    listProp={appConfig.LADISHARE.PAYMENT_FOR.filter((item) => (item.value !== 'VISITOR'))}
                                                    valueProp={commission.type}
                                                    hideLabel={true}
                                                    validProp={true}
                                                    classDropdown="dropdown-custom"
                                                    classSize="btn-size-34"
                                                    defaultTextProp="Chọn loại"
                                                    cbProp={eventSelectType}
                                                />
                                            </div>
                                            : <div className="ladiui form-group-custom">
                                                <label className="ladiui title-text-form-lever2" >Loại hoa hồng</label>
                                                <input type="text" readOnly className="ladiui form-control" placeholder="Nhập giá trị"
                                                    value={DefineConstCommission.PAYMENT_FOR_NAME[commission.type]} />
                                            </div>}
                                        <div className="ladiui form-group-custom">
                                            <label className="ladiui title-text-form-lever2" >Hoa hồng</label>
                                            <NumericFormat id="amount" name="amount"
                                                value={commission.amount}
                                                allowLeadingZeros
                                                allowNegative={false}
                                                decimalScale={2}
                                                thousandSeparator=","
                                                className="ladiui form-control"
                                                placeholder="Nhập giá trị"
                                                onValueChange={(values) => setCommission({ ...commission, amount: values.value })}
                                            />
                                        </div>
                                        {commission.use_commission_plan !== 'YES'
                                            ? <div className="ladiui form-group-custom">
                                                <label className="ladiui title-text-form-lever2" >Trạng thái</label>
                                                <LadiDropdown
                                                    listProp={appConfig.LADISHARE.COMMISSION_STATUS.filter((item) => ['PENDING', 'APPROVED'].includes(item.value))}
                                                    valueProp={commission.status}
                                                    hideLabel={true}
                                                    validProp={true}
                                                    classDropdown="dropdown-custom"
                                                    classSize="btn-size-34"
                                                    defaultTextProp="Chọn trạng thái"
                                                    cbProp={eventSelectStatus}
                                                />
                                            </div> : <></>}
                                    </>
                            }
                        </div>
                        {
                            commission.use_commission_plan === 'YES'
                                ? <>
                                    <div className="ladiui form-group-custom">
                                        <div className="ladiui flex-row">
                                            <label className="ladiui title-text-form-lever2">Thông tin gói hoa hồng</label>
                                        </div>
                                        <div className="font-size-14">Thông tin chi tiết gói hoa hồng mặc định</div>
                                    </div>
                                    <div className="order-info-content ladiui item bg-form bg-commissionn-plan mt-22">
                                        <table className="ladiui table text-left" style={{ margin: '12px' }}>
                                            <thead>
                                                <tr>
                                                    <th>Tên cấu hình</th>
                                                    <th>Tính hoa hồng theo</th>
                                                    <th>Giá trị tính</th>
                                                    <th>Phân cấp</th>
                                                    <th>Nhóm áp dụng</th>
                                                </tr>
                                            </thead>
                                            {commissionPlanDefault && commissionPlanDefault.commision_plan_payment_items
                                                ? <tbody>
                                                    {commissionPlanDefault.commision_plan_payment_items.map((item) => <tr key={item._id} className="ladiui table-vertical">
                                                        <td>{item.name}</td>
                                                        <td>
                                                            {appConfig.LADISHARE.PAYMENT_FOR.map((paymentFor) => ((paymentFor.value === item.payment_for) ? paymentFor.name : ''))}
                                                        </td>
                                                        <td>
                                                            {item.payment_type === DefineConstCommission.PAYMENT_TYPE.PAYMENT_TYPE_PERCENT
                                                                ? `${BaseHelper.formatNumber(item.payment_value)}%`
                                                                : BaseHelper.formatMoney(item.payment_value)}
                                                        </td>
                                                        <td className="ladiui text-pre">{item.level === -1 ? 'Tất cả các cấp' : `Level ${item.level}`}</td>
                                                        <td><ul className="list-group-aff">
                                                            {item.affiliate_group_id
                                                                ? item.affiliate_group_id.map((groupId) => <li key={groupId}>{commissionPlanDefault.affiliate_groups[groupId].name}</li>)
                                                                : <></>}
                                                        </ul></td>
                                                    </tr>)}

                                                </tbody>
                                                : <></>
                                            }

                                        </table>
                                    </div>
                                </> : <></>
                        }

                        {/* Thông tin khách hàng */}
                        {!['KPIS'].includes(commission.type)
                            ? <>
                                <div className="ladiui form-group-custom">
                                    <div className="ladiui flex-row">
                                        <label className="ladiui title-text-form-lever2">Thông tin {commission.type !== 'LEAD' ? 'khách hàng' : 'Lead'}</label>
                                    </div>
                                    <div className="font-size-14">Thiết lập thông tin {commission.type !== 'LEAD' ? 'khách hàng' : 'Lead'}</div>
                                </div>
                                <div className="order-info-content ladiui item bg-form bg-commissionn-plan mt-22">
                                    <div className="ladiui item bg-form-sub" style={{ paddingBottom: '16px' }}>
                                        <div className="d-grid-4 grid-template-177">
                                            {!isEdit
                                                ? <div className="ladiui pt-20">
                                                    <label className="ladiui title-text-form-lever2" >Khách hàng</label>
                                                    <div className="ladiui select-search dropdown">
                                                        <button
                                                            className={'ladiui btn btn-size-34 btn-outline-light dropdown-toggle w-100-percent'}
                                                            data-toggle="dropdown" style={{ maxWidth: '100%' }}>
                                                            <i></i>
                                                            <span className="ladiui dropdown-text" style={{ marginRight: 0 }}>
                                                                {customer.email || 'Tạo mới khách hàng'}
                                                            </span>
                                                        </button>
                                                        <ul className="ladiui dropdown-menu dropdown-right" style={{ width: '100%' }}>
                                                            <li className="ladiui search-group custom-people">
                                                                <input
                                                                    className="ladiui search-field dropdown-toggle form-control custom-people"
                                                                    onChange={(e) => setCustomerConditions({ ...customerConditions, keyword: e.target.value })}
                                                                    placeholder="Tìm kiếm"
                                                                />
                                                                <i className="ladiui icon icon-search"></i>
                                                            </li>
                                                            <li key={-1}
                                                                onClick={() => eventSelectCustomer({ _id: null })} >
                                                                <a className="ladiui dropdown-item" href="#/">
                                                                    Tạo mới khách hàng
                                                                </a>
                                                            </li>
                                                            {listCustomer.total > 0
                                                                ? listCustomer.items.map((item) => <li key={item._id}
                                                                    onClick={() => eventSelectCustomer(item)} >
                                                                    <a className="ladiui dropdown-item" href="#/">
                                                                        {item.name} ({item.email})
                                                                    </a>
                                                                </li>)
                                                                : <></>
                                                            }
                                                            {listCustomer.total > listCustomer.items.length
                                                                ? <li>
                                                                    <label className="ladiui search-checkbox default-text" ><a>...</a></label>
                                                                </li>
                                                                : <></>
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                                : <div className="ladiui pt-20">
                                                    <label className="ladiui title-text-form-lever2" >Khách hàng</label>
                                                    <input type="email" readOnly className="ladiui form-control" placeholder="Nhập giá trị"
                                                        value={customer.email || 'Email'} />
                                                </div>}

                                            <div className="ladiui pt-20">
                                                <label className="ladiui title-text-form-lever2">Email</label>
                                                <input type="text" id="customer_email" name="customer_email" className="ladiui form-control" placeholder="Nhập email" readOnly={!!commission.customer_id}
                                                    value={commission.customer_email || ''} onChange={(e) => setCommission({ ...commission, customer_email: e.target.value })} />
                                            </div>

                                            <div className="ladiui pt-20">
                                                <label className="ladiui title-text-form-lever2">Số điện thoại</label>
                                                <input type="text" id="customer_phone" name="customer_phone" className="ladiui form-control" placeholder="Nhập số điện thoại" readOnly={!!commission.customer_id}
                                                    value={commission.customer_phone || ''} onChange={(e) => setCommission({ ...commission, customer_phone: e.target.value })} />
                                            </div>

                                            <div className="ladiui pt-20">
                                                <label className="ladiui title-text-form-lever2">Họ tên</label>
                                                <input type="text" id="customer_name" name="customer_name" className="ladiui form-control" placeholder="Nhập tên"
                                                    value={commission.customer_name || ''} onChange={(e) => setCommission({ ...commission, customer_name: e.target.value })} />
                                            </div>
                                        </div>
                                        <div className="d-grid-2">
                                            <div className="ladiui pt-20">
                                                <label className="ladiui title-text-form-lever2">Địa chỉ</label>
                                                <input type="text" id="customer_address" name="customer_address" className="ladiui form-control" placeholder="Nhập địa chỉ"
                                                    value={commission.customer_address || ''} onChange={(e) => setCommission({ ...commission, customer_address: e.target.value })} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </> : <></>}
                        {/* End thông tin khách hàng */}

                        {/* Thông tin đơn hàng */}
                        {!['LEAD', 'NEW_CUSTOMER', 'KPIS'].includes(commission.type)
                            ? <>
                                <div className="ladiui form-group-custom">
                                    <div className="ladiui flex-row">
                                        <label className="ladiui title-text-form-lever2">Thông tin đơn hàng</label>
                                    </div>
                                    <div className="font-size-14">Thiết lập thông tin cho đơn hàng được tính hoa hồng.</div>
                                </div>
                                <div className="order-info-content ladiui item bg-form bg-commissionn-plan mt-22">
                                    <div className="ladiui item bg-form-sub d-grid-4" style={{ paddingBottom: '16px' }}>
                                        <div className="ladiui pt-20" style={{ width: '177px' }}>
                                            <label className="ladiui title-text-form-lever2">Mã đơn</label>
                                            <input type="text" id="code" name="total" className="ladiui form-control" placeholder="Nhập mã"
                                                value={commission.code || ''} onChange={(e) => setCommission({ ...commission, code: e.target.value })} />
                                        </div>
                                        <div className="ladiui pt-20">
                                            <label className="ladiui title-text-form-lever2">Tổng hoá đơn</label>
                                            <NumericFormat id="total" name="total"
                                                value={commission.total}
                                                allowLeadingZeros
                                                allowNegative={false}
                                                decimalScale={2}
                                                thousandSeparator=","
                                                className="ladiui form-control"
                                                placeholder="Nhập giá trị"
                                                onValueChange={(values) => setCommission({ ...commission, total: values.value })}
                                            />
                                        </div>
                                        <div className="ladiui pt-20" style={{ width: '177px' }}>
                                            <label className="ladiui title-text-form-lever2">Nguồn</label>
                                            <LadiDropdown
                                                listProp={appConfig.LADISHARE.APPS_INTEGRATE_FILTER_COMMISSION.filter((item) => item.value !== 'CUSTOM_API')}
                                                valueProp={commission.source}
                                                hideLabel={true}
                                                validProp={true}
                                                classDropdown="dropdown-custom dropdown-max-height"
                                                classSize="btn-sm-custom"
                                                defaultTextProp="Chọn nguồn"
                                                cbProp={eventSelectSource} />
                                        </div>

                                        <div className="ladiui pt-20" style={{ width: '177px' }}>
                                            <label className="ladiui title-text-form-lever2">Cửa hàng</label>
                                            <LadiDropdown
                                                listProp={getListIntegration()}
                                                valueProp={commission.shop_id}
                                                hideLabel={true}
                                                validProp={true}
                                                classDropdown="dropdown-custom dropdown-max-height"
                                                classSize="btn-sm-custom"
                                                defaultTextProp="Chọn cửa hàng"
                                                cbProp={eventSelectShopId} />
                                        </div>

                                        <div className="ladiui pt-10" style={{ width: '177px' }}>
                                            <label className="ladiui title-text-form-lever2">Phương thức ghi nhận</label>
                                            <input type="text" id="method" name="method" className="ladiui form-control" placeholder="Nhập phương thức"
                                                value={commission.method || ''} onChange={(e) => setCommission({ ...commission, method: e.target.value })} />
                                        </div>
                                        <div className="ladiui pt-10" style={{ width: '177px' }}>
                                            <label className="ladiui title-text-form-lever2">Địa chỉ IP</label>
                                            <input type="text" id="ip_address" name="ip_address" className="ladiui form-control" placeholder="Nhập địa chỉ"
                                                value={commission.ip_address || ''} onChange={(e) => setCommission({ ...commission, ip_address: e.target.value })} />
                                        </div>
                                    </div>
                                </div></>
                            : <></>}
                        {/* End thông tin đơn hàng */}

                        {/* Thông tin sản phẩm */}
                        {['PER_PRODUCT'].includes(commission.type)
                            ? <>
                                <div className="ladiui form-group-custom">
                                    <div className="ladiui flex-row">
                                        <label className="ladiui title-text-form-lever2">Thông tin sản phẩm</label>
                                    </div>
                                    <div className="font-size-14">Thiết lập thông tin cho sản phẩm được tính hoa hồng.</div>
                                </div>
                                <div className="order-info-content ladiui item bg-form bg-commissionn-plan mt-22">
                                    <div className="ladiui item bg-form-sub d-grid-4" style={{ paddingBottom: '16px', marginBottom: 0 }}>
                                        <div className="ladiui pt-20">
                                            <label className="ladiui title-text-form-lever2">Mã sản phẩm (SKU)</label>
                                            <input type="text" className="ladiui form-control" placeholder="Nhập mã"
                                                value={commission.product_code || ''} onChange={(e) => setCommission({ ...commission, product_code: e.target.value })} />
                                        </div>

                                        <div className="ladiui pt-20">
                                            <label className="ladiui title-text-form-lever2">Tên sản phẩm</label>
                                            <input type="text" className="ladiui form-control" placeholder="Nhập tên"
                                                value={commission.product_name || ''} onChange={(e) => setCommission({ ...commission, product_name: e.target.value })} />
                                        </div>

                                        <div className="ladiui pt-20">
                                            <label className="ladiui title-text-form-lever2">Giá</label>
                                            <NumericFormat
                                                value={commission.product_price}
                                                allowLeadingZeros
                                                allowNegative={false}
                                                decimalScale={2}
                                                thousandSeparator=","
                                                className="ladiui form-control"
                                                placeholder="Nhập giá sản phẩm"
                                                onValueChange={(values) => setCommission({ ...commission, product_price: values.value })}
                                            />
                                        </div>
                                        <div className="ladiui d-grid-2">
                                            <div className="ladiui pt-20">
                                                <label className="ladiui title-text-form-lever2">Số lượng</label>
                                                <NumericFormat
                                                    value={commission.product_quantity}
                                                    allowLeadingZeros
                                                    allowNegative={false}
                                                    decimalScale={2}
                                                    thousandSeparator=","
                                                    className="ladiui form-control"
                                                    placeholder="0"
                                                    onValueChange={(values) => setCommission({ ...commission, product_quantity: values.value })}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        commission.more_product_items ? commission.more_product_items.map((item) => <div key={item.id} className="ladiui item bg-form-sub d-grid-4" style={{ paddingBottom: '16px', marginBottom: 0 }}>
                                            <div className="ladiui">
                                                <label className="ladiui title-text-form-lever2">Mã sản phẩm (SKU)</label>
                                                <input type="text" className="ladiui form-control" placeholder="Nhập mã"
                                                    value={item.sku || ''}
                                                    onChange={(e) => changeItemProd(item, 'sku', e.target.value)}
                                                />
                                            </div>

                                            <div className="ladiui">
                                                <label className="ladiui title-text-form-lever2">Tên sản phẩm</label>
                                                <input type="text" className="ladiui form-control" placeholder="Nhập tên"
                                                    value={item.name || ''}
                                                    onChange={(e) => changeItemProd(item, 'name', e.target.value)}
                                                />
                                            </div>

                                            <div className="ladiui">
                                                <label className="ladiui title-text-form-lever2">Giá</label>
                                                <NumericFormat
                                                    value={item.price}
                                                    allowLeadingZeros
                                                    allowNegative={false}
                                                    decimalScale={2}
                                                    thousandSeparator=","
                                                    className="ladiui form-control"
                                                    placeholder="Nhập giá sản phẩm"
                                                    onValueChange={(values) => changeItemProd(item, 'price', values.value)}
                                                />
                                            </div>
                                            <div className="ladiui d-grid-2">
                                                <div className="ladiui">
                                                    <label className="ladiui title-text-form-lever2">Số lượng</label>
                                                    <NumericFormat
                                                        value={item.quantity}
                                                        allowLeadingZeros
                                                        allowNegative={false}
                                                        decimalScale={2}
                                                        thousandSeparator=","
                                                        className="ladiui form-control"
                                                        placeholder="0"
                                                        onValueChange={(values) => changeItemProd(item, 'quantity', values.value)}
                                                    />
                                                </div>
                                                <div className="ladiui">
                                                    <label className="ladiui title-text-form-lever2">&nbsp;</label>
                                                    <button type="button" className="ladiui font-size-12 btn btn-secondary"
                                                        onClick={() => eventRemoveProduct(item)}
                                                    >
                                                        <i className="ladiui icon icon-ldp-delete-black-sz"></i> Xoá
                                                    </button>
                                                </div>
                                            </div>
                                        </div>) : <></>
                                    }
                                    {
                                        commission.use_commission_plan === 'YES'
                                            ? <a href="#/" className="ladiui proviso"
                                                style={{ paddingTop: 0, paddingLeft: '16px' }}
                                                onClick={eventAddMoreProduct}>
                                                <img src="https://w.ladicdn.com/design-system/icons/icon-add-circle-outline.svg" alt="" />
                                                <p className="ladiui proviso-text">Thêm sản phẩm</p>
                                            </a> : <></>
                                    }

                                </div>

                            </>
                            : <></>}
                        {/* End thông tin sản phẩm */}

                    </div>
                    <div className="ladiui modal-footer custom-ldp-popupX mod">
                        <div className="ladiui modal-footer-link">
                        </div>
                        <div className="ladiui footer-modal-btn">
                            <button type="button" className="ladiui btn btn-secondary bold" onClick={() => eventCancelModal()}>{t('COMMON.CANCELED')}</button>
                            {!isEdit
                                ? <button type="button" className="ladiui btn btn-primary" onClick={eventSubmitCommission}>Tạo mới hoa hồng</button>
                                : <button type="button" className="ladiui btn btn-primary" onClick={eventSubmitCommission}>Cập nhật hoa hồng</button>}
                        </div>
                    </div>
                </div>
            </div>
        </div >
    </>;
}
export default (withTranslation()(ModalCommission));
